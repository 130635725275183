@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;600&display=swap);
.App {
  display: block;
  width: 525px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #d7d7d7;
  padding: 20px 20px 5px;
  border-radius: 5px;
  border-top: 5px solid #03363d;
  box-sizing: border-box;
  box-shadow: 0px 10px 8px #626262
}
@-webkit-keyframes appMiss {
  0% {opacity: 1; display: block;}
  95% {opacity: 0; display: block;}
  98% {opacity: 0; display: block;}
  100% {opacity: 0; display: none;}
}
@keyframes appMiss {
  0% {opacity: 1; display: block;}
  95% {opacity: 0; display: block;}
  98% {opacity: 0; display: block;}
  100% {opacity: 0; display: none;}
}

.App-miss {
  display: block;
  width: 525px;
  background-color: rgb(255, 255, 255);
  border: 1px solid hsl(0, 0%, 84%);
  padding: 20px 20px 0px;
  border-radius: 5px;
  border-top: 4px solid #03363d;
  box-sizing: border-box;
  box-shadow: 0px 10px 8px #626262;
  -webkit-animation-name: appMiss;
          animation-name: appMiss;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
}


.App-header {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin-bottom: 14px
}


.App-header-gratefull{
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin-bottom: 15px;
  height: 175px;
  position: relative;
}

@-webkit-keyframes showThanksMiss {
  from {opacity: 0; padding-top: 15px}
  to {opacity: 1; padding-top: 0px}
}

@keyframes showThanksMiss {
  from {opacity: 0; padding-top: 15px}
  to {opacity: 1; padding-top: 0px}
}
.App-header-gratefull p{
  position: absolute;
  opacity: 0;
  bottom: 0;
  width: 100%;
  height: -webkit-min-content;
  height: min-content;
  top: 0;
  margin: auto;
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  -webkit-animation-name: showThanksMiss;
          animation-name: showThanksMiss;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.App-header p {
  display: block;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: left; 
  width: 100%;
  page-break-after: always;
  -webkit-column-break-after: always;
          break-after: always;
  color: rgb(41, 41, 41);
  font-weight: 300;
}

.textArea {
  font-family: 'Be Vietnam Pro';
  width: 100%;
  font-weight: 300;
  display: block;
  box-sizing: border-box;
  border: 1px solid rgba(100,100,100,0.2);
  padding: 8px;
  font-size: 12px
}

.copyFrame {
  font-weight: 300;
  text-align: center;
  display: block;
  position: relative;
  margin: auto;
  color: rgb(10, 72, 243);
  width: 200px;
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.sendAvaliation {
  font-family: 'Be Vietnam Pro';
  border: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #f6a523;
  padding: 5px 12px;
  display: block;
  color: #fbfbfb;
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  transition: all 0.7s ease-out;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
.sendAvaliation:hover {
  background-color:#ffb339;
}
.sendAvaliation:disabled {
  cursor: not-allowed;
  color: #ffe8e8;
  background-color: #e6b86d;
}
.sendAvaliation:disabled:hover {
  color: #ffe8e8;
  background-color: #e6b86d;
}

.optionsCheck {
  margin-bottom: 10px;
  display: flex;
  margin-bottom: 10px;
  align-content: space-between;
  justify-content: space-between;
}

.optionsCheck button{
  font-size: 12px;
  font-weight: 300;
  margin: 5px;
  background: transparent;
  width: 34px;
  height: 34px;
  border-width: 1px;
  border-style: solid;
  border-radius: 18%;
  cursor: pointer;
}

.optionsCheck button:disabled{
  cursor: not-allowed;
  opacity: 0.4;
}
.optionsCheck button:first-child{
  margin-left: 0px
}
.optionsCheck button:last-child{
  margin-right: 0px
}
.option_yellow{
  border-color: rgb(255 211 30);
  color: rgb(214, 176, 22);
}
.option_green{
  border-color: green;
  color: green
}
.option_red{
  border-color: red;
  color: red
}

.option_yellowChecked{
  border-color: rgb(255 211 30) !important;
  color: #4c4c4c !important;
  background-color: rgb(255 211 30) !important;
}
.option_greenChecked{
  border-color: green !important;
  color: white !important;
  background-color: green !important;
}
.option_redChecked{
  border-color: red !important;
  color: white !important;
  background-color: red !important;
}
#otherAnswers {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
#otherAnswers button{
  width: auto;
  text-align: center;
  padding: 12px 15px;
  font-size: 10px;
}
#noAnswer{
  background: transparent;
  color: gray
}
#answerLater{
  background: rgb(219, 219, 219);
  color: rgb(25, 25, 25)
}


@media (max-width: 600px) {
  .App {
    width: 345px;
    border: 0.5px solid hsl(0, 0%, 84%);
    padding: 15px 15px 0px;
    border-top: 2px solid #03363d;
  }
  .App-miss {
    width: 345px;
    border: 0.5px solid hsl(0, 0%, 84%);
    padding: 15px 15px 0px;
    border-top: 2px solid #03363d;
  }
  
  .App-header {
    margin-bottom: 9px
  }
  
  .App-header-gratefull{
    margin-bottom: 10px;
    height: 100px;
  }
  
  .App-header-gratefull p{
    padding-top: 6px;
    font-size: 12px;
  }
  
  .App-header p {
    font-size: 9px;
    margin-top: 0;
    margin-bottom: 3px;
  }
  
  .textArea {
    border: 1px solid rgba(100,100,100,0.2);
    padding: 5px;
    font-size: 9px
  }
  
  .sendAvaliation {
    padding: 3px 7px;
    margin-top: 10px;
    font-size: 9px;
    padding: 5px;
  }
  
  .optionsCheck {
    margin-bottom: 5px;
  }
  
  .optionsCheck button{
    font-size: 8px;
    font-weight: 300;
    margin: 3px;
    width: 15;
    height: 15px;
    border-width: .5px;
  }
}

body {
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-table;
  background: transparent;
  font-family: 'Be Vietnam Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 999999;
  border: none;
  position: fixed;
  bottom: 15px;
  left: 0;
  height: 265px;
  width: 575px;
  right: 0;
  margin: auto;
  display: inline-table;
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 600px) {
  body {
    top: 115px;
    bottom: auto;
    left: 0;
    height: auto;
    width: 365px;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

