
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;600&display=swap');

body {
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-table;
  background: transparent;
  font-family: 'Be Vietnam Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  z-index: 999999;
  border: none;
  position: fixed;
  bottom: 15px;
  left: 0;
  height: 265px;
  width: 575px;
  right: 0;
  margin: auto;
  display: inline-table;
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 600px) {
  body {
    top: 115px;
    bottom: auto;
    left: 0;
    height: auto;
    width: 365px;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
